import Models, { Model } from "../components/Models";
import LandingSection from "../components/LandingSection2";
import { useState } from "react";
import { NextSeo } from "next-seo";

export default function Home() {
  const [featuredModels, setFeaturedModels] = useState<Model[]>([]);
  return (
    <div>
      <NextSeo
        title="Dvele Design Studio | Custom Modular Home Manufacturer"
        description={`At Dvele, our mission is to deliver a refreshingly simple client experience from "Hello" to "Welcome Home." We offer a wide-array of customizable, modern pre-fab homes that verifiably exceed all standards of quality and efficiency.`}
      />
      <LandingSection featuredModels={featuredModels} />
      <Models setFeaturedModels={setFeaturedModels} />
    </div>
  );
}
